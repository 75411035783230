import Joi from 'joi';
import { IApartmentContactPerson } from '@wohnsinn/ws-ts-lib';
import { SchemaOf } from 'core/schemas/apartment/cost';

export const APARTMENT_CONTACT_PERSON_FORM_SCHEMA = <SchemaOf<IApartmentContactPerson>>{
  firstName: Joi.string().required(),
  name: Joi.string().required(),
  directEmail: Joi.string().required(),
  mobile: Joi.string().required(),
  salutation: Joi.string().allow(''),
  addressRelease: Joi.boolean().required(),
};
